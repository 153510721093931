/* GENERAL */
:root {
  --ls-color-pink: #E4208F;
  --ls-color-beige: #FFF8EA;
  --ls-color-blue: #252543;
  --ls-color-grey: #BEBEC7;
  --ls-color-white: #FFFFFF;
  --ls-color-darkgrey: #939393;
}

#lsc-body {
  margin: 0;
}

.lsc-container *,
.lsc-container :before,
.lsc-container :after {
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
  color: var(--ls-color-blue);
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
}

/* GRID */
.lsc-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  padding: 75px 75px 55px;
  margin: 0 auto;
}

/* OVERVIEW */
.lsc-overview {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: 25px;
}

.lsc-fixed {
  position: sticky;
  top: 75px;
  z-index: 10;
}

video.lsc-video,
.lsc-video {
  height: 0;
  width: 0;
  visibility: hidden;
  background: var(--ls-color-white);
}

video.lsc-video.active,
.lsc-video.active {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  visibility: visible;
}

.lsc-overview-bar {
  display: none;
}

/* PRICES */
.lsc-price-info {
  padding: 20px 0 0;
  background-color: var(--ls-color-white);
}

.lsc-price-title {
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 7px;
}

.lsc-price-details {
  font-size: 15px;
  margin-bottom: 7px;
  display: block;
}

.lsc-prices {
  margin-bottom: 7px;
}

.lsc-price-main {
  font-size: 20px;
  font-weight: bold;
}

.lsc-price-original {
  text-decoration: line-through;
  color: var(--ls-color-darkgrey);
  text-decoration-color: var(--ls-color-blue);
  text-decoration-thickness: 1px;
  margin-right: 5px;
}

.lsc-price-secondary {
  margin-left: 8px;
  color: var(--ls-color-darkgrey);
}

/* CONTACT */
.lsc-contact {
  position: fixed;
  bottom: 22px;
}

.lsc-contact-cta {
  font-size: 15px;
  color: var(--ls-color-darkgrey);
  margin-right: 10px;
}

.lsc-contact-link:link,
.lsc-contact-link:visited {
  color: var(--ls-color-darkgrey);
  text-decoration: none;
}

.lsc-contact-link:hover,
.lsc-contact-link:active {
  text-decoration: underline;
}

.lsc-telephone-icon {
  width: 16px;
  margin-right: 10px;
  vertical-align: -2px;
}

/* CONTROLS */
.lsc-controls {
  flex-basis: 395px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 25px;
}

.lsc-tool-heading {
  font-size: 40px;
  font-weight: bold;
  margin: 0 0 50px;
}

.lsc-tool-heading-sm {
  display: none;
}

/* ALERT */
.lsc-alert {
  padding: 16px 75px 16px 22px;
  border: 1px solid var(--ls-color-grey);
  margin-bottom: 50px;
  position: relative;
}

.lsc-alert-sm {
  display: none;
}

.lsc-alert-text {
  font-size: 20px;
  line-height: 30px;
}

.lsc-alert-close {
  background: url('img/CrossLittleSheepBlue.svg') no-repeat center;
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 18px;
}

.lsc-alert-close:hover {
  background-image: url('img/CrossLittleSheepPink.svg');
}

/* SECTIONS */
.lsc-section {
  padding: 40px 0;
  border-bottom: 1px solid var(--ls-color-grey);
}

.lsc-section:first-child {
  padding-top: 0;
}

.lsc-section:last-child,
.lsc-section.no-separator {
  padding-bottom: 0;
  border-bottom: none;
}

.lsc-section-heading {
  font-size: 22px;
  font-weight: bold;
  margin: 0 0 10px;
}

.lsc-section.no-description .lsc-section-heading {
  margin-bottom: 20px;
}

.lsc-section-description {
  font-size: 20px;
  margin: 0 0 20px;
  line-height: 30px;
}

/* OPTIONS */
.lsc-option-name {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.lsc-option-byline,
.lsc-option-price {
  font-size: 15px;
}

.lsc-option-readmore,
.lsc-option-readmore:link,
.lsc-option-readmore:visited {
  color: var(--ls-color-grey);
  font-size: 15px;
  text-decoration: none;
}

.lsc-option-readmore:hover,
.lsc-option-readmore:active {
  text-decoration: underline;
  text-underline-offset: 2px;
}

/* RADIOBOX */
.lsc-radiobox {
  width: calc(100% - 2px);
  padding: 14px 22px;
  margin: 0 auto 20px;
  border: 1px solid var(--ls-color-grey);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.lsc-radiobox:hover,
.lsc-radiobox:focus-visible {
  border-color: var(--ls-color-blue);
  outline: none;
}

.lsc-radiobox.active {
  border-color: var(--ls-color-pink);
  outline: 1px solid var(--ls-color-pink);
}

.lsc-radiobox.active:focus-visible {
  border-color: var(--ls-color-blue);
  outline: 1px solid var(--ls-color-blue);
}

.lsc-radiobox:last-child {
  margin-bottom: 0;
}

.lsc-radiobox .lsc-option-price {
  float: right;
}

.lsc-radiobox .lsc-option-details {
  display: none;
}

.lsc-radiobox.active .lsc-option-details {
  display: block;
}

.lsc-radiobox .lsc-option-aspects {
  list-style: none;
  padding: 0;
  margin: 14px 0;
}

.lsc-radiobox .lsc-option-aspects li {
  font-size: 15px;
  line-height: 25px;
}

.lsc-radiobox .lsc-option-aspects li:before {
  content: '';
  display: inline-block;
  background: url('img/CheckmarkLittleSheepBlue.svg') no-repeat left center;
  width: 29px;
  height: 13px;
  padding-right: 15px;
}

/* CHECKBOX */
.lsc-checkbox {
  display: flex;
  align-items: center;
  user-select: none;
  margin-bottom: 20px;
}

.lsc-checkbox:last-child {
  margin-bottom: 0;
}

.lsc-checkbox-box {
  width: 23px;
  height: 23px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  border: 1px solid var(--ls-color-grey);
  margin-right: 15px;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  color: var(--ls-color-white);
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.lsc-checkbox-box:hover,
.lsc-checkbox-box:focus-visible,
.lsc-checkbox-box.active:focus-visible {
  border-color: var(--ls-color-blue);
  outline: none;
}

.lsc-checkbox-box.active {
  background: url('img/CheckmarkLittleSheep.svg') no-repeat center, var(--ls-color-pink);
  background-size: 16px;
  border-color: var(--ls-color-pink);
}

.lsc-checkbox .lsc-option-name {
  display: inline-block;
  cursor: pointer;
}

.lsc-checkbox .lsc-option-price {
  margin-left: auto;
  padding: 5px 0 0 10px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

/* SLIDER */
.lsc-slider-input {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  margin: 12px 0;
}

.lsc-slider-input:focus {
  outline: none;
}

.lsc-slider-input::-webkit-slider-runnable-track {
  background-color: var(--ls-color-pink);
  height: 2px;
}

.lsc-slider-input::-moz-range-track {
  background-color: var(--ls-color-pink);
  height: 2px;
}

.lsc-slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -11.5px;
  background-color: var(--ls-color-pink);
  border-radius: 50%;
  height: 25px;
  width: 25px;
  box-shadow: none;
}

.lsc-slider-input::-moz-range-thumb {
  background-color: var(--ls-color-pink);
  border: none;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.lsc-slider-input:focus-visible::-webkit-slider-thumb {
  outline: 1px solid var(--ls-color-pink);
  outline-offset: 2px;
}

.lsc-slider-input:focus-visible::-moz-range-thumb {
  outline: 1px solid var(--ls-color-pink);
  outline-offset: 1px;
}

.lsc-slider .lsc-option-name {
  margin-top: 12px;
  display: inline-block;
}

.lsc-slider .lsc-option-price {
  float: right;
  padding-top: 17px;
}

/* NUMERIC */
.lsc-numeric {
  display: flex;
  align-items: center;
}

input.lsc-numeric-input {
  border: 1px solid var(--ls-color-grey);
  border-radius: 0;
  width: 55px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 5px 3px 5px 10px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: 2px;
  outline: none;
  color: var(--ls-color-blue);
  font-size: 15px;
  transition: none;
}

input.lsc-numeric-input:focus {
  outline: none;
  border-color: var(--ls-color-blue);
}

input.lsc-numeric-input::placeholder {
  color: var(--ls-color-grey);
}

.lsc-numeric .lsc-option-name {
  display: inline-block;
  cursor: pointer;
}

.lsc-numeric .lsc-option-price {
  margin-left: auto;
  padding: 5px 0 0 10px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

/* FEEDBACK */
.lsc-field-feedback {
  color: var(--ls-color-pink);
  font-size: 20px;
  display: none;
  margin-bottom: 10px;
}

/* TEXTAREA */
textarea.lsc-textarea-input {
  width: 100%;
  border: 1px solid var(--ls-color-grey);
  border-radius: 0;
  resize: none;
  padding: 16px 25px;
  font-size: 20px;
  line-height: 30px;
  color: var(--ls-color-blue);
  outline: none;
  transition: none;
}

textarea.lsc-textarea-input:focus {
  outline: none;
  border-color: var(--ls-color-blue);
}

.lsc-textarea.invalid .lsc-field-feedback {
  display: block;
}

.lsc-textarea.invalid textarea.lsc-textarea-input {
  border-color: var(--ls-color-pink);
}

/* TEXTS */
input.lsc-text {
  border: 1px solid var(--ls-color-grey);
  border-radius: 0;
  padding: 14px 25px;
  width: 100%;
  font-size: 20px;
  color: var(--ls-color-blue);
  margin: 0 0 20px;
  outline: none;
  transition: none;
}

.lsc-text-group:last-child input.lsc-text {
  margin-bottom: 0;
}

input.lsc-text:focus {
  outline: none;
  border-color: var(--ls-color-blue);
}

.lsc-text-group.invalid .lsc-field-feedback {
  display: block;
}

.lsc-text-group.invalid input.lsc-text {
  border-color: var(--ls-color-pink);
}

/* DISCOUNT */
.lsc-discount {
  padding: 20px 0 0;
}

button.lsc-discount-toggle {
  font-family: inherit;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  word-spacing: normal;
  font-size: 20px;
  color: var(--ls-color-darkgrey);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

button.lsc-discount-toggle:hover,
button.lsc-discount-toggle:focus {
  color: var(--ls-color-darkgrey);
  background: none;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

.lsc-discount-content {
  height: 0;
  overflow: hidden;
  transition: height .3s;
}

.lsc-discount-content.open {
  height: 77px;
}

.lsc-discount-content.open.valid {
  height: 112px;
}

.lsc-discount-content input.lsc-text {
  margin: 20px 0 0;
}

.lsc-discount-content.valid input.lsc-text {
  background: url('img/CheckmarkLittleSheepPink.svg') no-repeat calc(100% - 25px) calc(50% + 1px);
  background-size: 16px;
  padding-right: 60px;
  width: 100%;
}

.lsc-discount-status {
  font-size: 20px;
  margin-top: 10px;
  display: block;
}

/* BUTTON */
button.lsc-button {
  font-family: inherit;
  line-height: normal;
  letter-spacing: normal;
  word-spacing: normal;
  font-size: 20px;
  font-weight: 500;
  color: var(--ls-color-white);
  background-color: var(--ls-color-pink);
  border: none;
  padding: 16px 25px;
  display: block;
  margin: 45px auto 10px;
  cursor: pointer;
  transition: transform .2s;
}

button.lsc-button:hover {
  color: var(--ls-color-white);
  background-color: var(--ls-color-pink);
  transform: scale(1.05);
}

button.lsc-button:focus {
  color: var(--ls-color-white);
  background-color: var(--ls-color-pink);
  outline: none;
}

button.lsc-button:focus-visible {
  color: var(--ls-color-white);
  outline: 1px solid var(--ls-color-blue);
}

/* ERROR */
.lsc-error {
  display: block;
  margin-top: 45px;
  color: var(--ls-color-pink);
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

.lsc-error + .lsc-button {
  margin-top: 30px;
}

@media (max-width: 1200px) {
  .lsc-controls {
    flex-basis: 335px;
  }

  .lsc-tool-heading {
    font-size: 38px;
  }
}

@media (max-width: 1000px) {
  .lsc-container {
    padding: 75px 30px 55px;
  }

  .lsc-overview,
  .lsc-controls {
    padding-left: 15px;
  }

  .lsc-contact-link {
    display: block;
    padding-top: 5px;
  }
}

@media (max-width: 767px) {
  #lsc-body {
    margin-top: 0;
  }

  .lsc-container {
    padding: 0 0 55px;
  }

  .lsc-tool-heading-lg {
    display: none;
  }

  .lsc-tool-heading-sm {
    display: block;
    margin-bottom: 35px;
    font-size: 39px;
  }

  .lsc-overview {
    flex-basis: 100%;
    padding: 0 15px;
  }

  video.lsc-video,
  .lsc-video {
    margin: 0 0 35px;
  }

  .lsc-price-info {
    display: none;
  }

  .lsc-overview-bar {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 35px;
    background-color: var(--ls-color-white);
    z-index: 10;
  }

  .lsc-overview-bar-inner {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0 15px;
    padding: 15px 0;
    border-bottom: 1px solid var(--ls-color-blue);
    width: calc(100% - 30px);
  }

  .lsc-overview-bar-total {
    text-align: right;
    font-size: 15px;
    font-weight: bold;
  }

  .lsc-overview-bar-style {
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 15px;
  }

  .lsc-contact {
    display: none;
  }

  .lsc-alert {
    margin-bottom: 35px;
  }

  .lsc-alert-lg {
    display: none;
  }

  .lsc-alert-sm {
    display: block;
  }

  .lsc-controls {
    flex-basis: 100%;
    padding: 0 15px;
  }

  .lsc-fixed {
    position: static;
    transform: none;
  }
}

@media (min-height: 885px) and (min-width: 768px) {
  .lsc-fixed {
    top: 50%;
    transform: translateY(-50%);
  }
}
